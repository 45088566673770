// GENERATED FILE (DO NOT MODIFIED THIS!!!)
export const localesMap = {
  en: { code: "en", name: "English" },
  pl: { code: "pl", name: "Polski" },
  cs: { code: "cs", name: "Český" },
  vi: { code: "vi", name: "Tiếng Việt" },
  hr: { code: "hr", name: "Hrvatski" },
  sq: { code: "sq", name: "Shqiptare" },
  sr: { code: "sr", name: "Српски" },
  de: { code: "de", name: "Deutsch" },
  it: { code: "it", name: "Italiano" },
  tr: { code: "tr", name: "Türk" },
  he: { code: "he", name: "עִבְרִית" },
  pt: { code: "pt", name: "Português" },
  es: { code: "es", name: "Español" },
  zh: { code: "zh", name: "中文" },
  uk: { code: "uk", name: "Українська" },
  fr: { code: "fr", name: "Français" },
  el: { code: "el", name: "Ελληνικά" },
  ru: { code: "ru", name: "Русский" },
  ro: { code: "ru", name: "Romanian" },
};

export const localesArr = [
  { code: "en", name: "English" },
  { code: "pl", name: "Polski" },
  { code: "cs", name: "Český" },
  { code: "vi", name: "Tiếng Việt" },
  { code: "hr", name: "Hrvatski" },
  { code: "sq", name: "Shqiptare" },
  { code: "sr", name: "Српски" },
  { code: "de", name: "Deutsch" },
  { code: "it", name: "Italiano" },
  { code: "tr", name: "Türk" },
  { code: "he", name: "עִבְרִית" },
  { code: "pt", name: "Português" },
  { code: "es", name: "Español" },
  { code: "zh", name: "中文" },
  { code: "uk", name: "Українська" },
  { code: "fr", name: "Français" },
  { code: "el", name: "Ελληνικά" },
  { code: "ru", name: "Русский" },
  { code: "ro", name: "Romanian" },
];

export type TLocale = keyof typeof localesMap;
